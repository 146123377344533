import React from 'react'

const About = () => {


    // react hook
    /*useEffect(() => {

        a.update();
        //eslint-disable-next-line
    }, [])*/
    return (
        <div>
            {/* about {a.state.name} studying in {a.state.class} */}
            about
        </div>
    )
}

export default About
